<template>
  <div class="content">
    <PageHeader :breadcrumbLinks="breadcrumbLinks" title="SMS" :subtitle="$tc('app.report', 2)"/>
    <div class="page-content container-fluid">
      <div class="row">
        <div class="col-lg-12 col-xl-12">
          <div class="card card-tabs">
            <div class="card-header">
              <ul class="nav nav-tabs">
                <li class="nav-item" role="presentation">
                  <router-link
                    :to="{ name: 'SmsStatReport' }"
                    class="nav-link"
                    replace
                  ><span class="material-symbols-outlined"> query_stats </span
                  ><span>{{ $tc('app.stats', 2) }}</span></router-link
                  >
                </li>
                <li class="nav-item" role="presentation">
                  <router-link
                    :to="{ name: 'SmsHistoryReport' }"
                    class="nav-link"
                    replace
                  ><span class="material-symbols-outlined"> history </span
                  ><span>{{ $t('app.history') }}</span></router-link
                  >
                </li>
                <li class="nav-item" role="presentation">
                  <router-link
                    :to="{ name: 'SmsUsageReport' }"
                    class="nav-link"
                    replace
                  ><span class="material-symbols-outlined">
                      insert_chart </span
                  ><span>{{ $tc('app.usage', 1) }}</span></router-link
                  >
                </li>
                <li class="nav-item" role="presentation">
                  <router-link
                    :to="{ name: 'SmsCampaignReport' }"
                    class="nav-link"
                    replace
                  ><span class="material-symbols-outlined"> campaign </span
                  ><span>{{ $tc('app.campaign', 2) }}</span></router-link
                  >
                </li>
                <li class="nav-item" role="presentation">
                  <router-link
                    :to="{ name: 'SmsReportExports' }"
                    class="nav-link"
                    replace
                  ><span class="material-symbols-outlined">
                      cloud_download </span
                  ><span>{{ $tc('app.file', 2) }}</span></router-link
                  >
                </li>
                <div class="opts_card">
                  <a
                    style="display: flex; align-items: center"
                    href="javascript:void(0)"
                    @click="showFilter"
                  ><span class="material-symbols-outlined title">
                      filter_list
                    </span></a
                  >
                  <a
                    style="display: flex; align-items: center"
                    href="javascript:void(0)"
                    @click="reload"
                  ><span class="material-symbols-outlined title">
                      refresh
                    </span></a
                  >
                  <form class="form-inline calendar_form">
                    <date-range-picker
                      class="form-control"
                      :format="'YYYY-MM-DD'"
                      :options="options"
                      @change="changeDate"
                    />
                  </form>
                  <span class="material-icons title calendar_icon">
                    calendar_today
                  </span>
                  <dropdown tag="span">
                    <template #text>
                      <i class="fas fa-file-export title"></i>
                    </template>
                    <template #items>
                      <a
                        class="dropdown-item"
                        href="javascript:void(0)"
                        @click="exportData('csv')"
                      >CSV</a
                      >
                      <a
                        v-if="$route.name === 'SmsStatReport'"
                        class="dropdown-item"
                        href="javascript:void(0)"
                        @click="exportData('png')"
                      >PNG</a
                      >
                      <a
                        v-if="$route.name === 'SmsStatReport'"
                        class="dropdown-item"
                        href="javascript:void(0)"
                        @click="exportData('svg')"
                      >SVG</a
                      >
                      <a
                        v-if="$route.name !== 'SmsStatReport'"
                        class="dropdown-item"
                        href="javascript:void(0)"
                        @click="exportData('xlsx')"
                      >XLSX</a
                      >
                      <a
                        v-if="$route.name !== 'SmsStatReport'"
                        class="dropdown-item"
                        href="javascript:void(0)"
                        @click="exportData('pdf')"
                      >PDF</a
                      >
                    </template>
                  </dropdown>
                </div>
              </ul>
            </div>
            <div class="card-body block-el">
              <router-view
                ref="view"
                @filter="changeCampaign"
                :filters="form.filters"
                :start-date="form.date.gte"
                :end-date="form.date.lte"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
    <modal ref="filter-modal" title="Filtros" size="xl">
      <h2 class="text-center m-b-10">Operadoras</h2>
      <div class="d-flex justify-content-between">
        <checkbox val="vivo" label="Vivo" v-model="filter.carrier"/>
        <checkbox val="claro" label="Claro" v-model="filter.carrier"/>
        <checkbox val="tim" label="TIM" v-model="filter.carrier"/>
        <checkbox val="oi" label="OI" v-model="filter.carrier"/>
        <checkbox val="other" label="Outras" v-model="filter.carrier"/>
      </div>
      <h2 class="text-center m-b-10 m-t-30">Status</h2>
      <div class="d-flex justify-content-between">
        <checkbox
          val="accepted"
          :label="$tc('app.accepted')"
          v-model="filter.status"
        />
        <checkbox
          val="queued"
          :label="$tc('app.queued')"
          v-model="filter.status"
        />
        <checkbox
          val="sending"
          :label="$tc('app.sending')"
          v-model="filter.status"
        />
        <checkbox
          val="failed"
          :label="$tc('app.failed')"
          v-model="filter.status"
        />
        <checkbox val="sent" :label="$tc('app.sent')" v-model="filter.status"/>
        <checkbox
          val="undelivered"
          :label="$tc('app.undelivered')"
          v-model="filter.status"
        />
        <checkbox
          val="delivered"
          :label="$tc('app.delivered')"
          v-model="filter.status"
        />
        <checkbox
          val="received"
          :label="$tc('app.received')"
          v-model="filter.status"
        />
      </div>
      <div class="row m-t-30">
        <div class="col-3">
          <h2 class="text-center">Remetente</h2>
          <div class="form-group">
            <input
              type="text"
              class="form-control"
              id="demoTextInput1"
              v-model="filter.from"
            />
          </div>
        </div>
        <div class="col-3">
          <h2 class="text-center">Destinatario</h2>
          <div class="form-group">
            <input
              type="text"
              class="form-control"
              id="demoTextInput1"
              v-model="filter.to"
            />
          </div>
        </div>
        <div class="col-2">
          <h2 class="text-center">Partes</h2>
          <div class="form-group">
            <input
              type="number"
              class="form-control"
              id="demoTextInput1"
              v-model="filter.segments"
            />
          </div>
        </div>
        <div class="col-4">
          <h2 class="text-center">Campanha</h2>
          <div class="form-group">
            <select-input
              :options="campaigns"
              v-model="filter.campaign_id"
              label="name"
              :reduce="(field) => field.id"
            ></select-input>
          </div>
        </div>
      </div>

      <template #footer>
        <div class="d-flex justify-content-between w-100">
          <button
            @click="cleanFilter"
            type="submit"
            class="btn btn-primary btn-outline"
          >
            {{ $t('app.clean') }}
          </button>
          <button @click="applyFilter" type="submit" class="btn btn-primary">
            {{ $t('app.apply') }}
          </button>
        </div>
      </template>
    </modal>
  </div>
</template>

<script>
// @ is an alias to /src
import moment from 'moment';
import PageHeader from '@/components/rebranding/PageHeader.vue';
import Dropdown from '@/components/Dropdown';
import Modal from '@/components/Modal';
import Checkbox from '@/components/rebranding/Checkbox.vue';
import Tab from '@/directives/Tab';
import SelectInput from '@/components/SelectInput.vue';

export default {
  name: 'SmsDashboard',
  components: {
    PageHeader,
    Dropdown,
    Modal,
    SelectInput,
    Checkbox,
    // Card,
  },
  directives: {
    Tab,
  },
  data() {
    return {
      breadcrumbLinks: {
        1: '/sms/reports/stat',
        2: '/sms/reports/stat',
      },

      filter: {
        carrier: [],
        status: [],
        from: '',
        to: '',
        segments: '',
        campaign_id: '',
      },
      options: {
        timePicker: false,
        autoApply: true,
        className: 'form-control',
        startDate: moment().format('DD/MM/YYYY'),
        maxSpan: {
          days: 31,
        },
        locale: {
          format: 'DD/MM/YYYY',
          daysOfWeek: ['Do', 'Seg', 'Ter', 'Qua', 'Qui', 'Sex', 'Sa'],
          monthNames: [
            'Jan',
            'Fev',
            'Mar',
            'Abr',
            'Mai',
            'Jun',
            'Jul',
            'Ago',
            'Set',
            'Otu',
            'Nov',
            'Dez',
          ],
        },
      },
      form: {
        category: 'sms',
        date: {
          gte: moment().format('YYYY-MM-DD'),
          lte: moment().format('YYYY-MM-DD 23:59:59'),
        },
        filters: {
          carrier: [],
          status: [],
          from: '',
          to: '',
          segments: '',
          campaign_id: '',
        },
      },
      campaigns: [],
    };
  },
  created() {
    this.fetchCampaigns();
  },
  methods: {
    showFilter() {
      this.$refs['filter-modal'].toggle();
    },
    reload() {
      this.$refs.view.fetch();
    },
    applyFilter() {
      this.form.filters = this.filter;
      this.$nextTick(() => {
        this.reload();
      });
    },
    changeCampaign(route, campaignId) {
      this.filter.campaign_id = campaignId;
      this.$router.push(route);
      this.applyFilter();
    },
    cleanFilter() {
      this.filter = {
        carrier: [],
        status: [],
        from: '',
        to: '',
        segments: '',
        campaign_id: '',
      };
      this.form.filter = {};
      this.reload();
    },
    changeDate(date) {
      if (date) {
        this.form.date.gte = date[0];
        this.form.date.lte = moment(date[1]).format('YYYY-MM-DD 23:59:59');

        this.$nextTick(() => {
          this.reload();
        });
      }
    },
    exportData(format) {
      this.$refs.view.exportData(format);
    },
    fetchCampaigns() {
      this.$store.dispatch('fetchSmsCampaigns').then((response) => {
        this.campaigns = response;
      });
    },
  },
};
</script>
<style lang="scss" scoped>
@import '~primevue/resources/themes/saga-blue/theme.css';
@import '~primevue/resources/primevue.min.css';

.table-responsive {
  min-height: 150px;
}

/* Rebranding */
.card.card-tabs .card-header .nav.nav-tabs {
  width: 100%;
  padding: 0 1rem;
  gap: 2.5rem;
}

.card.card-tabs .card-header .nav.nav-tabs li a {
  display: flex;
  align-items: center;
  gap: 5px;
  padding: 1.4rem 0.7rem 1.3rem;
  color: var(--clr-light-2);
}

.nav.nav-tabs .nav-item .nav-link.router-link-exact-active {
  color: var(--clr-yup-purple) !important;
  border-bottom: solid 2px var(--clr-yup-purple);
}

.nav.nav-tabs .nav-item .nav-link.router-link-exact-active i {
  color: var(--clr-yup-purple) !important;
}

.card.card-tabs .card-header .nav.nav-tabs li a i {
  font-size: 24px;
}

.nav-tabs .nav-item {
  margin-bottom: -2px;
}

.nav-tabs {
  border-bottom: 2px solid rgba(210, 221, 234, 0.5);
}

.rebranding .card .opts_card i {
  font-size: 22px;
  transition: 0.5s;
  padding: 5px;
}

.rebranding .card .opts_card i:hover {
  background-color: var(--background-icon-hover);
  border-radius: 5px;
}

.calendar_form {
  position: absolute;
  right: 70px;
  width: 38px;
  opacity: 0;
}

.calendar_form:hover ~ .calendar_icon,
.calendar_form:focus-within ~ .calendar_icon {
  background-color: var(--background-icon-hover);
  border-radius: 5px;
}

.calendar_form input {
  cursor: pointer;
  width: 38px;
}

/* Card-tabs mobile */
@media (max-width: 767.98px) {
  .card-tabs .nav-link span:last-of-type:not(.card-tabs .nav-link.router-link-active span) {
    display: none;
  }
  .card.card-tabs .card-header .nav.nav-tabs li a {
    transform: scale(1.1);
    height: 40px;
    padding: 20px 5px !important;
    padding-top: 30px !important;
  }
  .card.card-tabs .card-header .nav.nav-tabs {
    justify-content: space-between;
    gap: 5px !important;
  }
  .rebranding .card .opts_card {
    justify-content: flex-end;
    margin-left: unset !important;
    width: 100%;
    padding-top: 10px;
  }
  .rebranding .card .opts_card span {
    font-size: 26px;
    padding-top: 0;
  }
}
</style>
